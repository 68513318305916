import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { addHours } from 'date-fns';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { filterForNotIsNil } from '../../../utils/is-not-nil';
import { Destroyable, MixinRoot } from '../../../utils/mixins';
import { AppConfigService } from '../../app.config.service';
import { Dict } from '../../models/dict';
import { Group } from '../../models/group';
import { Keyed, MissionWithKey } from '../../models/keyed';
import { Mission, MissionStream } from '../../models/mission';
import { Muxer } from '../../models/muxer';
import { Organization } from '../../models/organization';
import { User, UserRole } from '../../models/user';
import { GroupService } from '../../services/group.service';
import { MissionService } from '../../services/mission.service';
import { MuxerService } from '../../services/muxer.service';
import { OrganizationService } from '../../services/organization.service';
import { ProfileService } from '../../services/profile.service';
import { UserService } from '../../services/user.service';
import { EditMissionResult } from './edit-mission-result';
import { EditableMission } from './editable-mission';
import { MissionEditorComponent } from './mission-editor.component';

@Injectable({
  providedIn: 'root',
})
export class MissionEditor extends Destroyable(MixinRoot) {
  private addMissionDialogOpened$ = new Subject();
  private editMissionDialogOpened$ = new Subject<MissionWithKey>();

  constructor(
    private dialogService: NbDialogService,
    private muxerService: MuxerService,
    private missionService: MissionService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private profile: ProfileService,
    private groupService: GroupService,
    private appConfig: AppConfigService
  ) {
    super();

    this.addMissionDialogOpened$
      .pipe(
        switchMap(() => this.getNewEditableMission()),
        switchMap((editable) => {
          const edited$ = this.dialogService.open(MissionEditorComponent, {
            hasBackdrop: true,
            closeOnBackdropClick: false,
            context: {
              dialogTitle: 'Create New Mission Details',
              mission: editable,
              appConfigArchivesEnabled: this.appConfig.enableArchives,
            },
          }).onClose as Observable<EditMissionResult | null | undefined>;
          return edited$.pipe(filterForNotIsNil());
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((edited) => {
        this.missionService.addMission(edited, null);
      });

    this.editMissionDialogOpened$
      .pipe(
        switchMap((initial) =>
          this.getEditableMission(initial).pipe(map((editable) => ({ editable, initial })))
        ),
        switchMap(({ editable, initial }) => {
          const edited$ = this.dialogService.open(MissionEditorComponent, {
            hasBackdrop: true,
            closeOnBackdropClick: false,
            context: {
              dialogTitle: 'Edit Current Mission Details',
              mission: editable,
              appConfigArchivesEnabled: this.appConfig.enableArchives,
            },
          }).onClose as Observable<EditMissionResult | null | undefined>;
          return edited$.pipe(
            filterForNotIsNil(),
            map((edited) => ({ initial, edited }))
          );
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ edited, initial }) => {
        this.missionService.addMission(edited, initial.key);
      });
  }

  public openAddMissionDialog() {
    this.addMissionDialogOpened$.next();
  }

  public openEditMissionDialog(mission: MissionWithKey) {
    this.editMissionDialogOpened$.next(mission);
  }

  private mapUsersToListItems(users: User[], organizationId: string) {
    function existsAndBelongsToOrganization(user: User): boolean {
      return (
        user.organizations != null &&
        user.organizations[organizationId] &&
        user.organizations[organizationId].role !== UserRole.Deleted
      );
    }

    function byName(a: User, b: User) {
      return a.name.localeCompare(b.name);
    }

    function userToListItem(user: User): ListItem {
      return {
        id: user.uid,
        text: user.name,
      };
    }

    return users.filter(existsAndBelongsToOrganization).sort(byName).map(userToListItem);
  }

  private getNewEditableMission(): Observable<EditableMission> {
    return combineLatest([
      this.userService.getUserList(),
      this.organizationService.getUserOrganizations(),
      this.groupService.getAllGroups(),
      this.organizationService.currentOrganization,
      this.profile.user,
    ]).pipe(
      take(1),
      map(([users, userOrganizations, groupsDict, currentOrg, currentUser]) => {
        const allUsers = this.mapUsersToListItems(users, currentOrg.key);
        const userOrganizationsItems = userOrganizations.map(organizationToListItem);
        const userGroupsItems = Object.entries(groupsDict)
          .filter(([_, ug]) => ug.organizationId && ug.organizationId === currentOrg.key)
          .map(([key, group]) => groupToListItem(key, group));
        const userCurrOrg = organizationToListItem(currentOrg);

        const newEditableMission: EditableMission = {
          title: 'New Mission',
          startDate: new Date(),
          endDate: addHours(new Date(), 2),
          location: null,
          description: 'Mission Description',
          archivesEnabled: currentOrg.archivesEnabled ?? false,
          allOrganizations: userOrganizationsItems,
          selectedOrganizations: [userCurrOrg],
          selectedGroups: [],
          allUsers,
          allGroups: userGroupsItems,
          selectedUsers: [{ id: currentUser?.uid!, text: currentUser?.name! }],
          selectedMobileStreams: {},
        };
        return newEditableMission;
      })
    );
  }

  private getEditableMission(mission: Mission): Observable<EditableMission> {
    return combineLatest([
      this.userService.getUsersDictionary(),
      this.organizationService.getUserOrganizations(),
      this.groupService.getAllGroups(),
      this.organizationService.currentOrganization,
    ]).pipe(
      take(1),
      map(([usersById, userOrganizations, groupsDict, currentOrg]) => {
        const allUsers = this.mapUsersToListItems(Object.values(usersById), currentOrg.key);
        const userOrganizationsItems = userOrganizations.map(organizationToListItem);
        const userGroupsItems = Object.entries(groupsDict)
          .filter(([_, ug]) => ug.organizationId && ug.organizationId === currentOrg.key)
          .map(([key, group]) => groupToListItem(key, group));

        const selectedUsers = mission.users
          ? Object.keys(mission.users).map((uid) => ({
              id: uid,
              text: usersById[uid]?.name ?? '',
            }))
          : [];

        const selectedGroups = mission.groups
          ? Object.keys(mission.groups).map((groupId) => ({
              id: groupId,
              text: groupsDict[groupId]?.name ?? '',
            }))
          : [];

        const selectedMobileStreams: Dict<MissionStream> = {};
        if (mission.streams) {
          for (const [streamId, stream] of Object.entries(mission.streams)) {
            selectedMobileStreams[streamId] = stream;
          }
        }

        const selectedOrganization = userOrganizations.find((o) => o.key === mission.organization);
        const selectedOrganizationItems =
          selectedOrganization == null ? [] : [organizationToListItem(selectedOrganization)];

        const editableMission: EditableMission = {
          title: mission.title,
          startDate: new Date(mission.start),
          endDate: new Date(mission.end),
          location: mission.location,
          description: mission.description,
          archivesEnabled: mission.archivesEnabled ?? currentOrg.archivesEnabled ?? false,
          allOrganizations: userOrganizationsItems,
          selectedOrganizations: selectedOrganizationItems,
          selectedGroups,
          allUsers,
          allGroups: userGroupsItems,
          selectedUsers,
          selectedMobileStreams,
        };
        return editableMission;
      })
    );
  }
}

function organizationToListItem(org: Keyed<Organization>): ListItem {
  return {
    id: org.key,
    text: org.title,
  };
}

function groupToListItem(id: string, group: Group): ListItem {
  return {
    id,
    text: group.name,
  };
}

function muxerToListItem(id: string, muxer: Muxer): ListItem {
  return {
    id,
    text: muxer.inputUrl,
  };
}
